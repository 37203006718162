import clsx from "clsx";
import React, { useState } from "react";
import { Link } from "gatsby";
import { useSiteMetadata } from "hooks/useSiteMetadata";
import { SanityProject } from "../../graphql-types";
import { _linkResolver, _localizeField } from "core/utils";
import SanityImageUI from "./ui/SanityImageUI";
import { AnimatePresence, motion, useInView } from "framer-motion";

type Props = {
  open: boolean;
};

// export const query = graphql``

const MenuProjects = ({ open }: Props): JSX.Element => {
  const { settings } = useSiteMetadata();
  const nav: SanityProject[] = settings.nav;
  // const { allSanityProject } = useSiteMetadata();
  const [image, setImage] = useState(null);

  return (
    <div
      className={clsx(
        "menu-projects fixed inset-sm md:inset-lg bottom-xl z-40",
        open ? "pointer-events-all" : "pointer-events-none"
      )}>
      <motion.div
        initial={{ y: "1em", opacity: 0 }}
        animate={open ? { y: "0em", opacity: 1 } : { y: "1em", opacity: 0 }}>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <nav>
              <ul className=''>
                {nav.map((item: SanityProject, i: number) => (
                  <motion.li
                    key={item.slug?.current}
                    animate={
                      open ? { y: "0", opacity: 1 } : { y: "1em", opacity: 0 }
                    }
                    transition={{
                      delay: i / 5,
                      ease: "easeOut",
                    }}>
                    <Link
                      to={_linkResolver(item)}
                      onMouseEnter={() => setImage(item.image)}
                      onMouseLeave={() => setImage(null)}>
                      {_localizeField(item.title)}
                    </Link>
                  </motion.li>
                ))}
              </ul>
            </nav>
          </div>
          <div className='col-md-6 hidden-sm'>
            {image && (
              <div className='preview bg-bg- h-full rounded-outline'>
                <SanityImageUI asset={image} objectFit='cover' />
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default MenuProjects;
