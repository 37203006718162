import React, { useEffect, useRef } from "react";
import LogoHeaderLeft from "../images/evzer-logo-nav-left.inline.svg";
import LogoHeaderSpacer from "../images/evzer-logo-nav-spacer.inline.svg";
import LogoHeaderRight from "../images/evzer-logo-nav-right.inline.svg";
import { useScroll } from "hooks/useScroll";
import styled from "styled-components";

type Props = {};

const Container = styled.div`
  .part {
    /* border: 1px solid red; */
  }
  .part--spacer {
    flex-grow: 0.001;
    flex-shrink: 0;
    flex-basis: 0px;
    will-change: flex;
    transform-origin: left;
    overflow: hidden;
    svg {
      width: 100%;
    }
  }
  /* .part--spacer,
  .part--at {
    transform: translateX(-0.7rem);
  } */
`;

const HeaderLogo = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollPercentage } = useScroll();

  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.flexGrow = `${scrollPercentage / 100}`;
  }, [scrollPercentage]);

  return (
    <Container className='flex justify-center'>
      <div className='part'>
        <LogoHeaderLeft />
      </div>

      <div className='part part--spacer' ref={ref}>
        <LogoHeaderSpacer />
      </div>
      <div className='part part--at'>
        <LogoHeaderRight />
      </div>
    </Container>
  );
};

export default HeaderLogo;
