import React, { useMemo } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbyImageDataArgs, getGatsbyImageData } from "gatsby-source-sanity";
import { sanityConfig } from "../../../config/sanity";
// import { SanityImageAsset } from 'graphql-types';
import styled from "styled-components";
import clsx from "clsx";
import { SanityImageAsset } from "../../../graphql-types";

type Props = {
  asset: SanityImageAsset | any;
  caption?: string;
  objectFit?: string;
  aspectRatio?: string;
};

/*
 needs placeholder blurred
*/
const ContainerImage = styled.div`
  &.is-cover {
    figure,
    .gatsby-image-wrapper {
      height: 100%;
      img {
        object-fit: cover !important;
      }
    }
  }
  &.is-contain {
    img {
      object-fit: contain !important;
    }
  }
  height: ${(props) => (props.objectFit === "cover" ? "100%" : "auto")};
  img[data-placeholder-image] {
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }
`;

//ImageAsset | ImageObject | ImageRef | string | null | undefined;
const SanityImageUI = ({
  asset,
  caption,
  objectFit = "contain",
  aspectRatio = "contain",
}: Props): JSX.Element => {
  // console.log(sanityConfig);
  const options: GatsbyImageDataArgs = {
    placeholder: "blurred", //placeholder?: 'blurred' | 'dominantColor' | 'none';
    fit: "fillmax",
    layout: "fullWidth",
    // formats: ['auto', 'webp'],
    // formats: ['webp'], //['auto', 'webp'],
  };
  const imageData = useMemo(() => {
    return getGatsbyImageData(asset, options, sanityConfig);
  }, [asset]);

  return (
    <ContainerImage
      objectFit={objectFit}
      className={clsx(
        "sanity-image",
        objectFit === "cover" ? "is-cover" : "is-contain",
        aspectRatio !== null ? aspectRatio : " "
      )
      
      }>
      <figure>
        {imageData && (
          <GatsbyImage
            image={imageData}
            alt={caption || "some alt"}
            loading='lazy'
            // className="w-full-"
            objectFit={objectFit || ""}
          />
        )}
      </figure>
    </ContainerImage>
  );
};

export default SanityImageUI;
