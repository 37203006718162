import React, { useEffect, useState } from "react";
import { _linkResolver, _localizeField } from "core/utils";
import { graphql, Link } from "gatsby";
import { useSiteMetadata } from "hooks/useSiteMetadata";
// import MenuNav from "./MenuNav";
import MenuSocial from "./MenuSocial";

import usePageContext from "context/PageContext";
import MenuProjects from "./MenuProjects";
import { subscribe, unsubscribe } from "pubsub-js";
import HeaderLogo from "./HeaderLogo";
import useStore from "store/useStore";
import clsx from "clsx";

type Props = {};

const Header = (props: Props) => {
  const { settings } = useSiteMetadata();
  const { pageContext } = usePageContext();
  // console.log(pageContext);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const token = subscribe("ROUTE_UPDATE", () => {
      setOpen(false);
    });

    return () => unsubscribe(token);
  }, []);

  useEffect(() => {
    document.body.classList.toggle("is-nav", open);
  }, [open]);

    const handleClick = (id:string) => {

      const  getOffset = ( el ) => {
          var _x = 0;
          var _y = 0;
          while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
              _x += el.offsetLeft - el.scrollLeft;
              _y += el.offsetTop - el.scrollTop;
              el = el.offsetParent;
          }
          return { top: _y, left: _x };
      }

      const el = document.querySelector('#' + id)

      const offset = getOffset(el)


      window.scrollTo({
      top: offset.top,
      behavior: "smooth",
    });
  }

  return (
    <header className='fixed bottom-0 w-full p-sm md:p-lg z-50 font-66 '>
      <div className='inner flex justify-between flex-col md:flex-row bg-bg z-50'>
        <div className='group item flex flex-nowrap'>
          <div className='b-r'>
            <Link to='/' className='site-name font-87'>
              {settings.seo?.metaTitle}
            </Link>
          </div>

          {pageContext.title && (
            <div className='hidden-sm h1'>{pageContext.title}</div>
          )}

          <div className={clsx('block md:hidden  border-l-[3px] md:border-l-0 md:border-r-[3px] border-solid uppercase', pageContext.title && ' border-l-[0px]')} >
            <button
              onClick={() => setOpen(!open)}
              className={clsx('uppercase', open && 'is-active')}>
             Our Works
            </button>
          </div>
        </div>
        {!pageContext.title && (
        <div className='group item  flex flex-nowrap mr-auto w-full md:w-auto'>
              <div className='md:border-l-[3px] border-solid border-black min-w-1/2 md:min-w-auto uppercase'>
                <button
                  className="our-expertise uppercase"
                  onClick={() => handleClick('our-expertise')}

                  >
                  Expertise
                </button>
              </div>
              <div className='md:border-r-[3px] border-l-[3px] border-solid border-black border-t-[3px] md:border-t-0 min-w-1/2 md:min-w-auto'>
                <button
                 className="a-propos uppercase"
                 onClick={() => handleClick('a-propos')}
                  >
                  About
                </button>
              </div>


          
          <div className='hidden md:block  md:border-r-[3px] border-solid border-black border-t-[3px] md:border-t-0'>
            <button
              onClick={() => setOpen(!open)}
             className={clsx('uppercase', open && 'is-active')}>
              Our Works
            </button>
          </div>

        </div>
         )}
        <div className='group flex items-center '>
          <div className='group-item-social p-sm- b-l'>
            {settings.navSocial && <MenuSocial input={settings.navSocial} />}
          </div>
          <div className='group-item-logo px-sm b-l h-full center-x-y'>
            <HeaderLogo />
          </div>
        </div>
      </div>
      <MenuProjects open={open} />
    </header>
  );
};

export default Header;
