//const website = require('./website')

module.exports = {
  fr: {
    default: true,
    label: 'Fr',
    path: 'fr',
    locale: 'fr',
    siteLanguage: 'fr',
    ogLang: 'fr_FR',
    projects: 'Projets',
    about: 'À propos',
    contact: 'Contact',
    type: 'Type',
    all: 'Tout',
    followUsOn: 'Suivez nous',
    services: 'Services',
    clients: 'Clients',
  },
  en: {
    default: false,
    label: 'En',
    path: 'en',
    locale: 'en',
    siteLanguage: 'en',
    ogLang: 'en_GB',
    projects: 'Projects',
    about: 'About',
    contact: 'Contact',
    all: 'All',
    followUsOn: 'Follow us on',
    services: 'Services',
    clients: 'Clients',
  },
};
