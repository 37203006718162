import "./src/styles/tailwind.css";
import "./src/styles/index.scss";
import React from "react";
import PubSub from "pubsub-js";
import type { GatsbyBrowser } from "gatsby";
// import { LocaleContext } from './src/context/LocaleContext';
import { PageContext } from "./src/context/PageContext";
import Layout from "./src/components/Layout";
import { LocaleContext } from "context/LocaleContext";


export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  // console.log(element);
  return (
    <PageContext {...props}>
      <LocaleContext>
        <Layout {...props}>{element}</Layout>
      </LocaleContext>
    </PageContext>
  );
};

export const onClientEntry = () => {
  const isTouch = "ontouchstart" in window ? true : false;

  if (isTouch) {
    document.documentElement.classList.add("touch");
  } else {
    document.documentElement.classList.add("no-touch");
  }
  // console.log('onClientEntry');
  setTimeout(() => {
    document.documentElement.classList.add("doc-ready");
  }, 1000);
};

export const onRouteUpdate = ({ location }) => {
  // console.log("2 onRouteUpdate");

  //console.log('new pathname', location.pathname)
  // window.scrollTo(0, 0);
  PubSub.publish("ROUTE_UPDATE", location);
};

export const onPreRouteUpdate = () => {
  // console.log("1 onPreRouteUpdate");
  if (document) {
    document.body.style.removeProperty("--project-bg");
    document.body.style.removeProperty("--project-color");
  }
  PubSub.publish("PRE_ROUTE_UPDATE");
};



// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   // console.log(location);
//   window.scrollTo(0, 0);

//   return false;
// };
