import { useStaticQuery, graphql } from "gatsby";
import { SanitySettings } from "../../graphql-types";
// import { SanitySettings } from '../../graphql-types';

export const useSiteMetadata = () => {
  const { site, settings, allSanityProject } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            defaultTitle
            defaultDescription
            defaultImage
            siteUrl
            ogLanguage
            author
          }
        }
        settings: sanitySettings {
          seo {
            ...seo
          }
          nav {
            _type
            slug {
              current
            }
            title {
              ...localeString
            }
            image: _rawImage(resolveReferences: { maxDepth: 10 })
          }
          navSocial {
            link
            label
            icon {
              asset {
                url
              }
            }
          }
        }
        allSanityProject(filter: { slug: { current: { ne: null } } }) {
          nodes {
            _id
            id
            _type
            slug {
              current
            }
            title {
              ...localeString
            }
            image: _rawImage(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    `
  );
  // console.log(global)
  return {
    buildTime: site.buildTime,
    site: site.siteMetadata,
    settings: settings as SanitySettings,
    allSanityProject: allSanityProject,
  };
};
