import React from "react";

export const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID || "",
  dataset: "production",
  useCdn: true,
};

export const serializers = {
  types: {
    code: (props) => (
      <pre>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
};
