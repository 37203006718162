import React from "react";
import { SanityLinkIcon } from "../../graphql-types";
// import SanityImageUI from './ui/SanityImageUI';

type Props = {
  input: SanityLinkIcon[];
};

const MenuSocial = ({ input }: Props) => {
  return (
    <nav className='menu-social p-border-width- pl-0 '>
      <ul className='grid grid-cols-2 grid-rows-2 gap-border-width bg-black '>
        {input.map((item, i) => (
          <li key={i} className=''>
            <a
              href={item.link || "#"}
              target='_blank'
              rel='noopener noreferrer'
              className=' '
              title={item.label || ""}>
              <div className='inner p-xs bg-bg center-x-y'>
                {item.icon && (
                  <i className={`icon-${item.label?.toLowerCase()}`}></i>
                )}
              </div>
            </a>
          </li>
        ))}
        {/* <li>
          <div className='inner p-xs bg-bg h-full'></div>
        </li> */}
      </ul>
    </nav>
  );
};

export default MenuSocial;
