import React, { ReactNode } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { PageProps } from "gatsby";
// import { useMemo } from "react";

const duration = 0.3;

const variants = {
  initial: {
    opacity: 0,
    // y: '100%',
    // y: "0vh",
  },
  enter: {
    opacity: 1,
    // y: " 0",
    transition: {
      duration: duration,
      delay: duration * 2,
      when: "beforeChildren",
      // ease: [0.775, 0, 0.17, 1.005],
    },
  },
  exit: {
    opacity: 0,
    // y: "-10vh",
    transition: {
      duration: duration / 2,
      // delay: duration,
      // when: 'beforeChildren',
    },
  },
};

type Props = {
  location: any;
  children: ReactNode;
};

const PageTransition = ({ location, children }: Props) => {
  const _onAnimationComplete = (e: Event) => {
    console.log(e);
  };
  return (
    <AnimatePresence>
      <motion.div
        className='page-transition'
        key={location.pathname}
        variants={variants}
        initial='initial'
        animate='enter'
        exit='exit'
        // ref={scrollRef}
        // onAnimationComplete={_onAnimationComplete}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default PageTransition;
