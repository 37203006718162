import React, { ReactNode, useEffect } from "react";
import { PageProps } from "gatsby";
import PageTransition from "./ui/PageTransition";
// import ScrollProgress from "./ui/ScrollProgressOld";
import { subscribe, unsubscribe } from "pubsub-js";
import Header from "./Header";
import Splash from "./ui/Splash";
import ScrollProgress from "./ui/ScrollProgress";

//{ children, pageContext, location }
let windowWith = 0;
const Layout: React.FC<PageProps> = ({ children, pageContext, location }) => {
  useEffect(() => {
    // console.log('heeeeeeee');
    // _handleDevice();
    _onResize();
    window.addEventListener("resize", _onResize);

    const tokenRoute = subscribe("ROUTE_UPDATE", (e: any, d: string) => {
      _onResize();
    });

    return () => {
      window.removeEventListener("resize", _onResize);
      unsubscribe(tokenRoute);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onResize = () => {
    document.documentElement.style.setProperty(
      "--app-height",
      window.innerHeight + "px"
    );

    const header = document.querySelector("header");
    if (header) {
      const headerBounding = header.getBoundingClientRect();

      if (window.innerWidth < 768 && headerBounding.height > 50)
        headerBounding.height = 50;

      document.documentElement.style.setProperty("--header-height", 50 + "px");
    }

    windowWith = window.innerWidth;
  };

  // const _handleDevice = () => {
  //   const isSafari =
  //     navigator.vendor &&
  //     navigator.vendor.indexOf("Apple") > -1 &&
  //     navigator.userAgent &&
  //     navigator.userAgent.indexOf("CriOS") == -1 &&
  //     navigator.userAgent.indexOf("FxiOS") == -1;

  //   // console.log({ isSafari });
  //   if (isSafari) {
  //     document.documentElement.classList.add("is-safari");
  //   }
  // };



  
  return (
    <div id='page' className="overflow-x-hidden">
      <Header />
      <PageTransition location={location}>
        <main className='z-10'>{children}</main>
      </PageTransition>
      <Splash />
      {/* <ScrollProgress /> */}
    </div>
  );
};

export default Layout;
