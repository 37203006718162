import React, { createContext, useContext, ReactNode } from 'react';
import { PageProps } from 'gatsby';
// import { Script } from "gatsby";

const WrapperContext = createContext({});

// interface PageContextProps {
//   location?: object;
//   children: ReactNode;
//   pageContext: object;
// }

const PageContext: React.FC<PageProps> = (props) => {
  const { children, pageContext, location } = props;

  return (
    <WrapperContext.Provider value={{ pageContext, location }}>
      {children}
    </WrapperContext.Provider>
  );
};

// export default PageContext;
export { WrapperContext, PageContext };

export default function usePageContext() {
  return useContext(WrapperContext);
}
