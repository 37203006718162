import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import AnimatedTextCharacter from "components/modules/AnimatedTextCharacter";
import useStore from 'store/useStore';

type Props = {};

const Splash = (props: Props) => {
  const [index, setIndex] = useState<number>(0);
  const [hide, setHide] = useState<boolean>(false);
  const titles: string[] = [
    "Évry",
    // "Éveil",
    // "Évader",
    // "Évaluer",
    // "Évoluer",
    // "Évoquer",
    // "Évident",
    "Événement",
    "Evzer",
    // "Evzer Agency",
  ];
	const { setIsLoading } = useStore();



  useEffect(() => {
    setTimeout(() => {
      _animate()
    }, 850)
  }, [index])
  

  const _animate = () => {
    if (index < titles.length - 1) setIndex((prev) => prev + 1);
    else _reveal();
  };


  const _reveal = () => {
    console.log("reveal");
    setHide(true);
    setIsLoading(true)
  };

  return (
    <motion.div
        initial={{ y: 0 }}
        animate={{ y: hide ? '-100vh' : 0 }} 
        transition={{ duration: .35,ease: 'easeOut' }} 
        
        className='splash bg-yellow text-primary fixed inset-0 h-screen w-screen center-x-y text-xxl font-87 z-50'
    >
        <div
          className='title text-left w-6/12- uppercase'
          style={{
            lineHeight: "1.4",
          }}>
            <AnimatedTextCharacter key={index} text={titles[index]} />
        </div>
    </motion.div>
  );
};

export default Splash;
